<template>
  <b-card>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        :fields="fields"
        :items="items"
        responsive
        :per-page="perPage"
        v-if="items.length"
      >
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(deleted_at)="data">
          <div class="d-flex">
            {{ data.item.deleted_at | formatdate }}
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <b-button
              variant="primary"
              size="sm"
              @click="revert(data.item.group_id)"
            >
              <span v-if="isloading && data.item.group_id == groupId"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Revert</span>
            </b-button>
            <b-button
              variant="danger"
              class="ml-1"
              size="sm"
              @click="
                gotopermanentDelete(data.item.group_id, data.item.group_name)
              "
            >
              Delete
            </b-button>
          </div>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="dataTable"
      use-router
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text style="max-width: 100%; word-wrap: break-word">
        Do you really want to permanent delete
        <span
          class="text-primary"
          style="max-width: 100%; display: inline-block"
          >"{{ group_name }}"</span
        >
        ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="permanentDelete()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BModal,
  BCardText,
  BSpinner,
  BPaginationNav,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BPaginationNav,
  },
  data() {
    return {
      // org_id: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      org_id: this.$store.state.app.org_id,
      groupId: "",
      group_name: "",
      items: [],
      fields: [
        { key: "index", label: "#" },
        // { key: "group_id", label: "Group ID" },
        { key: "group_name", label: "Group Name" },
        { key: "group_description", label: "Group Description" },
        { key: "groupOwner.full_name", label: "Group Owner" },
        {
          key: "groupOwner.profile.o_profile[0].org_name",
          label: "Organization",
        },
        { key: "deleted_by", label: "Deleted By" },
        { key: "deleted_at", label: "Deleted At" },
        { key: "action", label: "Action" },
      ],
      openDeleteModal: false,
      isloading: false,
      pagination_pos: "center",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      loading: false,
    };
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY hh:mm A");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load();
    },
  },
  created: function () {
    this.load();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    load: function (search = false) {
      this.loading = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/deleted-asset-group/?org_id=" +
          this.org_id +
          "&page=" +
          this.currentPage,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    revert: function (group_id) {
      this.isloading = true;
      this.groupId = group_id;
      let formData = new FormData();
      formData.append("group_id", this.groupId);
      //   let data = {
      //     asset_id: this.assetId,
      //   };

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url: process.env.VUE_APP_BASEURL + "asset/group/revert-asset-group/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isloading = false;
        this.load();
        // if (res.data.id) {
        //   this.$router.push({ name: "Users" });
        // }
      });
    },
    gotopermanentDelete: function (group_id, group_name) {
      this.openDeleteModal = true;
      this.groupId = group_id;
      this.group_name = group_name;
    },
    permanentDelete: function () {
      let formData = new FormData();
      formData.append("group_id", this.groupId);

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url: process.env.VUE_APP_BASEURL + "asset/group/hard-delete-assetgrp/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>